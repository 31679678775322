import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://images.alphacoders.com/760/76051.jpg" className="App-logo" alt="logo" />
        <p>
        Mr. Gorbachev,
        tear down <a
          className="App-link"
          href="https://amazon.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          this interenets
        </a>
        </p>
      </header>
    </div>
  );
}

export default App;
